form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
}

form input,
form textarea {
    background-color: var(--primary-color);
    /* background-color: var(--color-text); */
    /* filter: invert(5%); */
    padding: .7rem;
    margin: .3rem 0;
    border: none;
    color: var(--color-text);
    transition: filter ease .3s;
    transition: all ease .5s; /* because of the light/dark modes */
}

form textarea {
    height: 150px;
}

.light-mode form input, .light-mode form textarea {
    background-color: var(--white);
    color: var(--black);
}

form input:focus-visible, form textarea:focus-visible {
    outline: none;
    filter: invert(5%);
    transition: filter ease .3s;
}

form input[type='search']:focus {
    box-shadow: 0 0 0 4px rgba(31, 116, 152, 0.3);
}

form button.primary-action-button {
    padding: .7rem 2rem;
    margin: .3rem 0;
    border: none;
}

.light-mode form button.primary-action-button {
    background: var(--dark-color-bkg);
    color: var(--color-text);
    border: solid var(--color-text) .1rem;
}


