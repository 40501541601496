.robots_footer_container {
    position: relative;
    height: 10rem;
    display: flex;
    place-content: flex-end;

}


.robots_footer_container img {
    max-width: 100%;
    /* min-width: fit-content; v mozile to dela bordel */
    position: relative;
   /* bottom: .2rem; */
   filter: var(--filter-for-black-img);
    pointer-events: none;

}

.robots_footer_container ~ .hr {
    position: relative;
    top: -2.8rem;
    border-top: solid var(--color-text) .1rem; /*must be same as .project.container bottom border*/
    
}

.footerCredits {
    padding: 2rem 0 1rem;
}

.socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 2rem;
}

.socials .socials-img {
    height: 1.5rem;
    filter: var(--filter-for-black-img);
    transition: filter ease .3s;
}

.hr ~ div a {
    transition: filter ease .3s;
}


@media (min-width:500px) {
    .robots_footer_container {
        height: 15rem;
        }

    .robots_footer_container ~ .hr {
        top: -4.2rem;
    }

    .hr ~ div a:hover {
        filter: brightness(80%);
    }
    
    .light-mode .hr ~ div a:hover {
        filter: invert(30%);
    }


}