
.galleryContainer {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 200;
  display: grid;
  place-items: center;
}

.galleryImg {
  max-width: 90%;
  max-height: 95vh;
  cursor: pointer;
}


.project.container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;
    /* padding-bottom: 1rem; */
    position: relative;
    width: 100%;
}

.project h3 {
    text-transform: uppercase;
    font-weight: 100;
    margin: 1rem 0;
}

.projects-hr {
    border-bottom: solid var(--color-text) .1rem;
    width: 80%;
    /* position: absolute; */
    /* bottom: 0; */
    /* left: 50%;
    transform: translateX(-50%); */
    margin: 0 auto;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, var(--darkest-color), var(--light-color));

}

.light-mode .projects-hr {
    border-image-slice: 0;
    border-image-source: none;
}



.projectsGithubLogo {
    height: 1.5rem;
    /* padding-right: 1rem; */
    filter: var(--filter-for-black-img);
    position: absolute;
    top: .4rem;
    left: .7rem;
}


.col-2 {
 display: none;
}


@media (min-width: 1000px) {

    .project.container {
        flex-direction: row;
        padding-bottom: 3rem;
    
    }

    .project.container:nth-of-type(2n) .col-1 {
        order: 2;
    }

    
    .projects-hr {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .col-1 {
        flex: 1 1 50%;
    }
    
    .col-2 {
        display: block;
        margin-top: 2rem; /*zavisle na '[class*="constellation_header"] img' */
        flex: 1 1 50%;
        padding: 2rem 0 2rem 2rem;
        position: relative;
        max-height: 100%;
    }


    .project_photo_phone_width {
        display: none;
    }

}

