/*Loader from svg - hardcoded, for 400px and for 200px*/


.Loader.container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: var(--primary-color);
}


.Loader .frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  aspect-ratio: 1/1;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid red 2px; */
  /* overflow: hidden; */
}


.Loader  svg {
	width: 200px;
	aspect-ratio: 1/1;
	position: absolute;
	/* border: solid green 3px; */
}


.Loader circle {
    fill: none;
	stroke: var(--primary-color); 
	/* stroke: red; */
	stroke-width: 95%; /**/
	animation: strokeAnimation 2s forwards infinite;
	stroke-dasharray: 760; /*délka mezery pro zakrytí celého kruhu!*/
	transform: rotate(-90deg);
	transform-origin: center center;
}


/*-----------------------------------------------------*/
@media (max-width: 400px) {
	.Loader circle {
		/* transform: scale(0.5, 0.5) translate(-20px, -20px); */
		animation: strokeAnimationSmaller 2s forwards infinite;
		stroke-width: 118%;
		stroke: var(--primary-color); /**/
	}

	@keyframes strokeAnimationSmaller {
		/* 0% {stroke-dashoffset: 760; transform: rotate(-90deg) scale(0.5, 0.5) translate(-25px, -25px);}
		50% {stroke-dashoffset: 0; transform: rotate(-90deg) scale(0.5, 0.5) translate(-25px, -25px);}
		50.0001%  {transform: rotate(-90deg) scale(0.5, -0.5) translate(-25px, -25px);}
		100% {stroke-dashoffset: 760; transform: rotate(-90deg) scale(0.5, -0.5) translate(-25px, -25px);} */

		0% {transform: rotate(-90deg) scale(0.5, -0.5) translate(-25px, -25px);}
		50% {stroke-dashoffset: 760; transform: rotate(-90deg) scale(0.5, -0.5) translate(-25px, -25px);}
		50.0001%  {stroke-dashoffset: 760; transform: rotate(-90deg) scale(0.5, 0.5) translate(-25px, -25px);}
		100% {stroke-dashoffset: 0; transform: rotate(-90deg) scale(0.5, 0.5) translate(-25px, -25px);}
	}
}



.Loader img {
	position: absolute;
    width: 150px;
	filter: invert(90%);
}

/* .Loader  img:nth-of-type(1){
	opacity: 0;
	animation: changeZIndex 8s forwards infinite 6s; /*dart vader, 2s obecne + 4s delay
}

.Loader  img:nth-of-type(2){
	opacity: 1;
	animation: changeZIndex 8s forwards infinite 2s; /*stormtrooper, 2s delay jsou schvalne u obou
} 
*/

@keyframes strokeAnimation {
	/* takhle zacina cely napis:
	
	0% {stroke-dashoffset: 760; transform: scaleY(1) rotate(-90deg);}
	50% {stroke-dashoffset: 0; transform:scaleY(1) rotate(-90deg);}
	50.0001%  {transform: rotate(-90deg) scaleY(-1);}
	100% {stroke-dashoffset: 760; transform: rotate(-90deg) scaleY(-1);} */

	0% {stroke-dashoffset: 0; transform: rotate(-90deg) scaleY(-1);}
	50% {stroke-dashoffset: 760; transform: rotate(-90deg) scaleY(-1);}
	50.0001%  {stroke-dashoffset: 760; transform: rotate(-90deg) scaleY(1);}
	100% {stroke-dashoffset: 0; transform:  rotate(-90deg) scaleY(1);}

}

/* @keyframes changeZIndex {
	0%, 49% {opacity: 1}
	50%, 99% {opacity: 0}
	100%{opacity: 1}
}
 */

@media (min-width: 400px) {

	.Loader .frame {
		width: 200px;

	}


	.Loader img {
		width: 200px;
	}
	
	.Loader svg {
		width: 252px;
	}

}