

*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Source Code Pro', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, button, #toggleNavButton, img {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-size: 14px;
  line-height: 1.3rem;
  min-height: 100vh;
  --primary-color: rgb(27, 27, 27);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[class*="constellation_header"] img {
  filter: var(--filter-for-black-img);
  margin: 1.5rem auto; /*se asi zmeni, a margin bude mit container projektu*/
  pointer-events: none;
  /* -webkit-touch-callout: none */
}


.constellation_header_4 img {
  height: calc(.6rem * 4);
}

.constellation_header_5 img {
  height: calc(.6rem * 5);
}

.constellation_header_6 img {
  height: calc(.6rem * 6);
}

.constellation_header_7 img {
  height: calc(.6rem * 7);
}

.constellation_header_12 img {
  height: calc(.6rem * 12);
}

.constellation_header_14 img {
  height: calc(.6rem * 14);
}

@media (min-width:500px) {

  body {
    font-size: 16px;
    line-height: 1.4rem;
  }

  [class*="constellation_header"] img {
      margin: 2rem auto;
      /*se asi zmeni, a margin bude mit container projektu*/
    }


  .constellation_header_4 img {
      height: calc(1rem * 4);
    }

    .constellation_header_5 img {
      height: calc(1rem * 5);
    }

    .constellation_header_6 img {
      height: calc(1rem * 6);
    }

    .constellation_header_7 img {
      height: calc(1rem * 7);
    }

    .constellation_header_12 img {
    height: calc(1rem * 12);
  }

    .constellation_header_14 img {
      height: calc(1rem * 14);
    }
}