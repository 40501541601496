
.Home.container {
    width: 100%;
    max-width: 100vw;
}

.Home .hero-bkg {
    background-color: var(--primary-color);
}

.light-mode .Home .hero-bkg {
    background-color: var(--nav-li-bkg-color);
}

.Home .hero-section {
    height: calc(100vh - 8rem);
    position: relative;
    width: 100%;
}

.Home .About.constellation_header_6 {
    margin-top: 3rem;
    pointer-events: none;
}

.Home .buttons_container {
    justify-content: flex-start;
}

/*--------- WEB DEV PORTFOLIO ----------*/

.hero__heading {
    position: absolute;
    padding: 10rem 2rem 1rem;
    text-align: left;
}

.hero__h1 {
    line-height: 2.5rem;
}

/* .hero__h1--position {
    color: var(--accent-color);
} */

.hero__socials {
    padding: 1.5rem 0;
    display: flex;
    gap: 1rem;
    position: relative;
}


.hero__socials .socials-img {
    height: 1.5rem;
    filter: var(--filter-for-black-img);
    transition: filter ease .3s;
}

.hero__svg--move {
    position: absolute;
    transform: rotate(80deg);
    left: 10%;
    top: 10%;
    animation-name: falconFlight;
    animation-timing-function: cubic-bezier(0.75, 1, 0.1, 1); /*cubic-bezier(.03,.8,1,.34)
    /* animation-timing-function: ease; */
    animation-duration:  15s;
    animation-iteration-count:  infinite;
}


.hero__svg--falcon {
    width: 50px;
    fill: var(--darkest-color);
    aspect-ratio: 1/1;
    z-index: 100;
}


@keyframes falconFlight {
    0%, 30% {
        left: 0%;
        top: 0%;
        transform: rotate(80deg);
    }

    40% {
        transform: rotate(40deg); /*20-90*/
    }

    100% {
        transform: rotate(90deg); /*50-70*/
        top: 60%; /*10-60*/
        left: 110%;
    }
    
} 
/*--------- ABOUT IMAGE ---------- */



/* --------------SKY------------------*/


.sky_hero_container {
    border-top-left-radius: 50% 20%;
    border-top-right-radius: 50% 20%;
    width: 100%;
    height: 95%;
    position: absolute;
    bottom: 0;
    /* background-image: linear-gradient(to top, #30cfd0 0%, rgb(51, 8, 103) 100%);*/
    overflow: hidden;
    box-shadow: inset 0 0 20px rgba(51 8 103 / 0.8);
}

.sky_hero_container::before {
    background-image: linear-gradient(to top,
            var(--lightest-color-gradient) 0%,
            var(--light-color-gradient) 20%,
            var(--dark-color-gradient) 50%,
            var(--darkest-color-gradient) 100%);
    content: "";
    position: absolute;
    width: 100%;
    height: 120%;
    top: 0px;
    left: 0px;
    animation: moving-gradient 10s ease infinite;
}

/*------ STARS -------*/

.sky_hero_star {
    background-color: var(--lightest-color);
    position: absolute;
    animation: stars 4s ease infinite both;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
}


/* ---------------SHOOTING STARS-----------------------*/

.sky_hero_shooting_star_container {
    position: absolute;
    inset: 0;
    transform: rotate(30deg);
    /* background-color: rgba(242, 96, 96, 0.4); */
}

.sky_hero_shooting-star {
    --shooting-color: var(--lightest-color);
    opacity: 0;
    background-color: transparent;
    width: 0.1px;
    height: 0.1px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent var(--shooting-color) transparent transparent;
    transform: scaleX(25);
    left: var(--left);
    border-radius: 30%;
    position: absolute;
    animation-name: shooting-star;
    /* animation-duration:  3s; */
    animation-timing-function:  ease-in;
    /*animation-iteration-count: infinite; /* pri infinite a ruznych delays se animace usekne jak interval vyvola nove funkci*/
}

.sky_hero_shooting-star-2 {
    --shooting-color: var(--lightest-color);
    opacity: 0;
    background-color: transparent;
    width: 0.1px;
    height: 0.1px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent var(--shooting-color) transparent transparent;
    transform: scaleX(25);
    left: var(--left);
    border-radius: 30%;
    position: absolute;
    animation-name: shooting-star-2;
    /* animation-duration:  3s; */
    animation-timing-function:  ease-in;
    /*animation-iteration-count: infinite; /* pri infinite a ruznych delays se animace usekne jak interval vyvola nove funkci*/
    /*animace se automaticky nerestartuji --> musis odebrat a znovu pridat class, a nebo pridat pokazde class jinou a to s jinou animaci, jako ted ja.. */
}


.sky_hero_shooting-star::after,  .sky_hero_shooting-star-2::after{
    width: 100%;
    height: 100%;
    background-color: rgba(245 231 107 / 0.5);
    position: absolute;
    content: "";
    box-shadow: 0 0 3px 2px rgba(245 231 107 / 0.5),
        -3px 0 4px 3px rgba(200 100 200 / 0.5);
}


@keyframes shooting-star {
    0% {
        transform: scaleX(25);
        opacity: 0;
        left: var(--left);
    }

    1%, 95% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }



    100% {
        transform: scaleX(25);
        opacity: 0;
        left: 100%;
    }
}

@keyframes shooting-star-2 {
    0% {
        transform: scaleX(25);
        opacity: 0;
        left: var(--left);
    }

    1%, 95% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }


    100% {
        transform: scaleX(25);
        opacity: 0;
        left: 90%;
    }
}

@keyframes stars {
    0% {
        opacity: .7;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        opacity: .7;
    }
}

@keyframes moving-gradient {
    0% {
        top: 0;
    }

    50% {
        top: -20%;
    }

    100% {
        top: 0;
    }
}


/*------------------------------------*/

@media (min-width:350px) {
    .robots_hero_container {
        max-width: 50%;
    }
}

@media (min-width:500px) {

    .Home .hero-section {
            height: calc(100vh - 5rem);
        }

    

    /* .sky_hero_container {
        border-radius: 200% 200% 0 0;
    }
     */

     .hero__socials a:hover .socials-img {
        filter: invert(70%);
    }
    
    .light-mode .hero__socials a:hover .socials-img {
        filter: var(--black-img-hover-filter);
    }
    

    
}

@media (min-width:800px) {
    .robots_hero_container {
        max-width: 35%;
    }

    .header-container-phones {
        height: 60%;
    }

    .hero__heading {
        max-width: 700px;
        margin: 5% 15%;
     }

   
    

}




/*------zmenit sky na obloucek uprostred obrazovky pri >1400px */
@media (min-width:1400px) {
    .sky_hero_container {

        border-top-left-radius: 50% 75%;
        border-top-right-radius: 50% 75%;

    }
}