
/*-----MOBILE FIRST */

/*-------- by default dark mode ----------*/
  
.App {
  --primary-color: rgb(27, 27, 27);
  --secondary-color: rgb(37, 25, 72);
    /* --dark-color-bkg: #217875; tahle barva je bozi jako bkg, ale nefunguje mi se zbytkem*/
  --dark-color-bkg: #393939;
  --darkest-color: #0D4C92;
  --nav-li-bkg-color: var(--darkest-color);
  --dark-color: #59C1BD;
  /*these two are the same colors, needed because of changing the color on img from black */
  --light-color: #A0E4CB;
  --light-color-filter-from-black: invert(93%) sepia(11%) saturate(968%) hue-rotate(92deg) brightness(95%) contrast(88%);
  /*----------r*/
  --lightest-color: #CFF5E7;
  text-align: center;
  /* color: var(--lightest-color); */
  --contrast-color: var(--light-color);
  position: relative;
  --white: #D7D7D7; /* == invert(90%) z cerne */
  --black: var(--primary-color);
  --color-text: var(--white);
  --color-text-hover: #B2B2B2; /* == invert(70%) z cerne*/
  position: relative;
  min-height: 100vh;
  background-color: var(--dark-color-bkg);
  transition: background-color ease .5s; /* because of the light/dark modes */
  --filter-for-black-img: invert(90%);
  --lightest-color-gradient: var(--dark-color);
  --light-color-gradient: var(--darkest-color); 
  --dark-color-gradient: #42014a;
  --darkest-color-gradient: var(--black);
  --accent-color: var(--light-color);
}


.light-mode {
  --dark-color-bkg: #c97f6f;
  --nav-li-bkg-color: var(--dark-color-bkg);
  --darkest-color: #e16592;
  --dark-color: #e99987;
  /*these two are the same colors, needed because of changing the color on img from black */
  --light-color: #F2D388;
  --light-color-filter-from-black: invert(87%) sepia(40%) saturate(484%) hue-rotate(338deg) brightness(99%) contrast(91%);
  /*-----------------r*/
  --lightest-color: #f0dd8a;
  --contrast-color: var(--dark-color);
  --color-text: var(--black);
  --filter-for-black-img: none;
  --darkest-color-gradient: var(--darkest-color);
  --dark-color-gradient: var(--dark-color);
  --light-color-gradient: var(--light-color);
  --lightest-color-gradient: var(--lightest-color);
  --black-img-hover-filter: invert(30%);
  --accent-color: var(--lightest-color);
}



/* ------ width of content ----------*/

header > *, footer > * {
  width: 90%;
  margin: 0 auto;
}

main {
  padding-top: 5rem; /*because of the fixed header*/
  margin: 0 auto;
}

main > * {
  max-width: 1400px;
}


/*--------- colors & background-colors -------*/

header, main, footer {
  color: var(--color-text);
  position: relative;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-absolute-positioning-replacement {
  width: 1px;
  height: 400px;
}

header {
  background-color: var(--primary-color);
}

.light-mode header {
  background-color: var(--nav-li-bkg-color);
}


/*------------------*/

.App header {
  height: 5rem;
  position: fixed;
  left: 0;
  right: 0;
  display: grid;
  align-items: center;
  z-index: 100;
}

.App header nav {
  display: flex;
  position: relative;
  width: 90%;
}

.App header .nav-icon.active ~ nav {
  position: fixed;
  inset: 0;
  width: 100vw;
  display: grid;
  place-content: center;
  background-color: var(--nav-li-bkg-color);
  z-index: 2;
  transition: background-color ease .5s; /* because of the light/dark modes */
}

.App .logo {
  padding: 1rem 0;
  flex: 1;
  text-align: left;
  width: max-content;
}

.App header .nav-icon.active ~ nav .logo {
  position: absolute;
  top: .6rem;
  left: 5%; /* because nav has 90% width all the time*/
}


.App .logo a {
  color: var(--light-color);
}

.App .logo img {
  height: 1.5rem;
  margin-top: 0;
  filter: invert(90%);
}

.light-mode .logo img {
  filter: none;
}

a {
  color: var(--color-text);
  text-decoration: none;
  font-weight: 600;
  }
  
  a.active {
    color: var(--contrast-color);
  }



.buttons_container {
 display: flex;
 justify-content: center;
 gap: 0.5rem;
}

.primary-action-button, .secondary-action-button {
  background: linear-gradient(to left, var(--darkest-color), var(--light-color));
  display: inline-block;
  padding: .5rem .3rem;
  margin: 2rem 0 1rem;
  border: none;
  position: relative;
  color: var(--dark-color-bkg);
  font-weight: 600;
  transition: filter ease .3s;
  cursor: pointer;
  transition: all ease .5s; /* because of the light/dark modes */
}

.secondary-action-button {
  border: solid var(--color-text) .1rem;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, var(--darkest-color), var(--light-color));
  background: transparent;
  color: var(--text-color);
}

.secondary-action-button.about__button--version {
  border-image-source: none;
  margin: 0 0 1rem;
}

.secondary-action-button.about__button--version.active {
  border-color: var(--light-color);
  color: var(--light-color);
}

.button--show-more {
      border-color: var(--light-color);
      color: var(--light-color);
      background: transparent;
      border: none;
      cursor: pointer;
      transition: all ease .5s;
        /* because of the light/dark modes */
      height: 4rem;
      text-align: center;
}


.light-mode [class*='action-button'] {
  background: var(--color-text);
  border-image-slice: 0;
  border-image-source: none;
}

.secondary-action-button.github-link {
  padding-left: 3rem;
}

.light-mode .secondary-action-button {
  background: var(--dark-color-bkg);
  
}

.text-container, .contact__form {
  margin: 0 auto;
  text-align: justify;
  max-width: 90%;
}

.contact__form {
  width: 600px;
}

.text-container p {
  margin: 0 0 1rem;
}

.rounded-corners {
  border-radius: 20px;
}


.App header .nav-ul {
  list-style: none;
  display: none;
  gap: 1.5rem;
  flex-direction: column;
  text-transform: uppercase;
}

.App header .nav-li, .nav-li__mode-switch {
  height: 1.2rem;
  filter: invert(90%);
  transition: filter ease .3s;
}

.light-mode header .nav-li, .light-mode .nav-li__mode-switch {
  filter: none;
}


.App header a.active .nav-li {
filter: var(--light-color-filter-from-black);
}


.App header .nav-icon {
  position: absolute;
  width: fit-content;
  right: 0;
  z-index: 3;
  padding: 1rem;
  background-image: url(./img/hamburgr.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 1.5rem;
  margin: 1.5rem;
  filter: invert(90%);
  cursor: pointer;
}

.light-mode header .nav-icon {
  filter: none;
}

.App header .nav-icon.active {
  position: absolute;
  right: 0;
  z-index: 3;
  background-image: url(./img/x.png);
}

.App header .nav-icon.active ~ nav .nav-ul {
  display: flex;
}



.App header .mode-switch-icon {
  position: relative;
  /* background-color: rgba(0 0 0 /.3); */
  margin: 0 auto;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.App header .nav-li__mode-switch {
  /* background-color: rgba(238, 10, 10, 0.5); */
  height: 2rem;
}

.App header .nav-li__mode-switch-ball {
  position: absolute;
  top: 10%;
  left: 8%; /*8, 52*/
  width: 40%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: var(--light-color);
  transition: left ease .5s;
  pointer-events: none;

}

.App.light-mode header .nav-li__mode-switch-ball {

  left: 52%; /*8, 52*/
}

.App main {
  z-index:1;
}

.App footer {
  padding-top: 5rem;
  z-index: 0;
}




.App footer a[href*="linkedin"] {
 /* background-color: red; */
 color: inherit; /*prebere barvu od parent el*/
}


.Thanks.container {
  padding-top: 4rem;
}

.Thanks.container h1 {
  padding: 2rem;
  line-height: 2.4rem;
}

.Thanks.container p {
  padding: 1rem 2rem;
}

/*----------- pictures, breakpoint 1000px ----------------------*/


.aboutImageContainer, .project_photo_phone_width {
  position: relative;
  margin: 3rem 0;
}


.aboutImg, .project_photo_phone_width img {
  max-width: 100%;
  width: 600px;
}

.aboutImg {
  aspect-ratio: 4/3;
  object-fit: cover;
  filter: brightness(0.9) contrast(1.1);
}




/*--------------*/

/*--- techStack icons --------------*/

.about__icons--stack {
  /* display: flex;
  gap: 1rem;
  flex-wrap: wrap; */
  color: var(--accent-color);
  font-size: .6rem;
  text-align: center;
  /* justify-content: space-between; */
  display: grid;
  grid-template-columns: repeat(auto-fill, 4rem);
  grid-gap: 1rem;
  justify-content: space-between;
}

.about__icons--stack svg {
  height: 3rem;
}

.about__icons--stack svg > * {
  fill: var(--accent-color);
}

/* .about__icons--stack svg .icon--lighter-color {
  filter: brightness(120%);
} */

/*---------------*/


/* mobile first */

@media (min-width: 330px) {
  [class*='action-button'] {
      padding: .5rem .6rem;
  }

  .buttons_container {
    gap: 0.8rem;
   }

  
}


@media (min-width: 650px) {
  header > *, footer > * {
    width: 90%;
  }

  main > * {
    width: 90%;
    margin: 0 auto;
  }

  .App header .nav-li:hover {
    filter: invert(75%);
  }

  .light-mode header .nav-li:hover {
    filter: var(--black-img-hover-filter);
  }

  .mode-switch-icon:hover {
    filter: brightness(90%);
  }

    [class*='action-button']:hover, .button--show-more:hover {
    filter: brightness(80%);
  }

  


  .App header .nav-li

  .App header nav, .App header .nav-icon.active ~ nav {
    position: relative;
    background-color: transparent;
    width: 90%;
    padding-top: 2rem; 
    /* top: 1rem; */
    display: flex;
  }

  .App .logo {
    order: 0;
    padding: 0;
  }

  .App .logo img {
    height: 1.2rem;
    margin-top: 0rem;
  }

  .App header .nav-icon {
    display: none;
  }

  .App header .nav-li {
    height: .8rem;
  }

  .App header .nav-li__mode-switch {
    height: 1.3rem;
  }

  .App header .nav-ul, .App header .icon-nav:not(.active) ~ nav .nav-ul {
    flex-direction: row;
    display: flex;
  }
}

@media (min-width: 1000px) {
      
  .flex-parent-two-coll {
    display: flex;
    gap: 10%;
    margin: 2rem auto;
    max-width: 1400px;
    width: 80%;
  }
  
  .text-container {
    max-width: 600px;
    flex: 0 0 50%;
  }

  .flex-container-col-2 {
    width: 600px;
  }

  [class*='action-button'] {
      padding: .5rem 1rem;
  }

  .buttons_container {
    gap: 2rem;
   }

   /*--- pictures ----*/

    .aboutImageContainer, .desktop-pictures-container {
        width: 100%;
        aspect-ratio: 4/3;
        margin: 0;
        position: relative;
    }

    .aboutImg, .desktop-pictures-container img {
        width: 100%;
    }

    .desktop-pictures-container img {
      cursor: pointer;
      transition: filter ease .3s;
    }

  
    .desktop-pictures-container img:hover {
      filter: brightness(90%);
    }


    .aboutImageContainer::after, .desktop-pictures-container::after {
        display: inline-block;
        width: 100%;
        aspect-ratio: 4/3;
        position: absolute;
        left: 1rem;
        top: 1rem;
        content: '';
        border: solid var(--color-text) .1rem;
        border-image-slice: 1;
        border-image-source: linear-gradient(to left, var(--darkest-color), var(--light-color));
        z-index: -1;
    }

    .light-mode .aboutImageContainer::after, .light-mode .desktop-pictures-container::after  {
        border-image-slice: 0;
        border-image-source: none;
    }
}

@media (min-width: 1200px) {
  .App header .nav-ul {
    gap: 2.5rem;
  }

}

@media (min-width: 1600px) {
    .App .logo img {
      height: 2rem; /*1.2*/
    }

    .App header .nav-li {
      height: 1.6rem; /*.8*/
    }

    .App header .nav-li__mode-switch {
      height: 1.8rem;
    }

    .App header .nav-ul {
      gap: 3.5rem;
    }

}